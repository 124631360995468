<template>
  <a-modal
    title="充值审核"
    width="50%"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form layout="inline">
        <a-col>
          <a-row>
            <a-col :md="12" :sm="24">
              <a-form-item label="企业名称">
                <span>{{ details.enterpriseName }}</span>
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="企业联系人">
                <span>{{ details.linkName }}</span>
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="联系人手机号">
                <span>{{ details.linkMobile }}</span>
              </a-form-item>
            </a-col>

            <a-col :md="12" :sm="24">
              <a-form-item label="充值积分">
                <span>{{ details.integral }}</span>
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="支付金额">
                <span>{{ details.price }}</span>
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="支付凭证">
                <viewer :images="[details.voucher]" v-if="details.voucher">
                  <img
                    :src="details.voucher"
                    alt=""
                    style="border: 0.5px solid #ccc; padding: 3px; margin: 3px; width: 100px; height: 60px"
                  />
                </viewer>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
      </a-form>
      <div style="height: 15px"></div>
      <a-form :form="form" :selfUpdate="true">
        <a-row :gutter="24">
          <a-col :md="24" :sm="24">
            <a-form-item label="审核状态：" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select
                allow-clear
                placeholder="请选择"
                v-decorator="['result', { rules: [{ required: true, message: '请选择审核状态！' }] }]"
                @change="handleSelectChange"
              >
                <a-select-option :value="2">通过</a-select-option>
                <a-select-option :value="3">拒绝</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24" v-if="+auditStatus === 3">
            <a-form-item label="拒绝原因" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-textarea
                placeholder="请输入"
                v-decorator="['refuseReason', { rules: [{ required: true, message: '请输入拒绝原因！' }] }]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { enterpriseEdit } from '@/api/modular/mallLiving/enterprises'
import { rechargeIntegralRecordCheckResult } from '@/api/modular/mallLiving/recharge'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 4 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 20 },
        sm: { span: 120 },
      },
      visible: false,
      confirmLoading: false,
      details: {},
      form: this.$form.createForm(this),
      pictureLoading: false,
      id: '',
      auditStatus: '',
      cos: null,
    }
  },

  methods: {
    // 初始化方法
    init(record) {
      this.visible = true
      this.id = record.id
      this.details = record
    },
    handleSelectChange(value) {
      this.auditStatus = value
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          rechargeIntegralRecordCheckResult({ id: this.id, ...values }).then((res) => {
            if (res.success) {
              this.$message.success('审核成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
