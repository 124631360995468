<template>
  <!-- 商家管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="6" :sm="24">
                  <a-form-item label="企业名称：">
                    <a-input v-model="queryParam.enterpriseName" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <!-- 状态 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="审核状态：">
                    <a-select v-model="queryParam.result" allow-clear placeholder="请选择">
                      <a-select-option :value="1">待审核</a-select-option>
                      <a-select-option :value="2">成功</a-select-option>
                      <a-select-option :value="3">失败</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-button style="background: #52c41a; color: #fff" @click="batchAudit(1)">批量通过</a-button>
                  <a-button style="margin-left: 8px; background: #f5222d; color: #fff" @click="batchAudit(2)"
                    >批量拒绝</a-button
                  >
                  <a-button type="primary" style="margin-left: 8px" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
            :row-selection="rowSelection"
          >
            <!-- 账号状态插槽 -->
            <span slot="result" slot-scope="text">
              <a-tag color="blue" v-if="+text === 1"> 待审核 </a-tag>
              <a-tag color="green" v-else-if="+text === 2"> 成功 </a-tag>
              <a-tag color="red" v-else> 失败 </a-tag>
            </span>
            <span slot="voucher" slot-scope="text">
              <viewer :images="[text]" v-if="text">
                <img
                  :src="text"
                  alt=""
                  style="border: 0.5px solid #ccc; padding: 3px; margin: 3px; width: 100px; height: 60px"
                />
              </viewer>
            </span>

            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a style="color: #52c41a" @click="$refs.editForm.init(record)" v-if="+record.result === 1">审核</a>
              <a-divider type="vertical" v-if="+record.delFlag === 1" />
              <a @click="$refs.detailForm.detail(record)">详情</a>
            </span>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <!-- 分页组件 -->
    <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
      <a-pagination
        :pageSize="page.pageSize"
        :current="page.pageNo"
        show-size-changer
        :page-size-options="['10', '20', '50', '100']"
        show-quick-jumper
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="pageChange"
        @showSizeChange="sizeChange"
      >
      </a-pagination>
    </div>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <edit-form @ok="getList" ref="editForm"></edit-form>
    <reject-reason @ok="getList" ref="rejectReason"></reject-reason>
  </div>
</template>

<script>
import editForm from './editForm'
import detailForm from './detailForm.vue'
import rejectReason from './rejectReason.vue'
import { rechargeIntegralRecordCheckResultBatch, rechargeIntegralRecordPage } from '@/api/modular/mallLiving/recharge'
const columns = [
  {
    title: '序号',
    align: 'left',
    width: '70px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '企业名称',
    align: 'left',
    width: '150px',
    dataIndex: 'enterpriseName',
  },
  {
    title: '企业联系人',
    width: '100px',
    align: 'left',
    dataIndex: 'linkName',
  },
  {
    title: '联系人手机号',
    width: '100px',
    dataIndex: 'linkMobile',
    align: 'left',
  },

  {
    title: '充值积分',
    width: '130px',
    dataIndex: 'integral',
    align: 'left',
  },

  {
    title: '支付金额',
    width: '150px',
    dataIndex: 'price',
    align: 'left',
  },
  {
    title: '支付凭证',
    width: '180px',
    dataIndex: 'voucher',
    align: 'left',
    scopedSlots: {
      customRender: 'voucher',
    },
  },
  {
    title: '审核状态',
    width: '100px',
    dataIndex: 'result',
    align: 'left',
    scopedSlots: {
      customRender: 'result',
    },
  },
  {
    title: '创建时间',
    width: '150px',
    dataIndex: 'createTime',
    align: 'left',
  },

  {
    title: '操作',
    align: 'center',
    width: '150px',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]
export default {
  data() {
    return {
      columns,
      data: [],
      recordItem: null,
      total: 0,
      loading: false,
      queryParam: {},
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      selectedRowKeys: [],
      selectRowId: [],
    }
  },
  components: {
    editForm,
    detailForm,
    rejectReason,
  },
  mounted() {
    this.initTableData()
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectRowId = selectedRows.map((item) => {
            return item.id
          })
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: +record.result !== 1, // Column configuration not to be checked
            name: record.id,
          },
        }),
      }
    },
  },
  methods: {
    batchAudit(type) {
      if (!this.selectRowId.length) {
        return this.$message.error('请选择要处理的数据')
      }

      if (+type === 1) {
        // 通过
        this.$confirm({
          title: '提示',
          content: '确定批量通过当前选择的充值记录吗？通过后不能撤销当前操作',
          onOk: () => {
            this.audit()
          },
        })
      }
      if (+type === 2) {
        // 拒绝
        this.$refs.rejectReason.init(this.selectRowId)
      }
    },
    audit() {
      rechargeIntegralRecordCheckResultBatch({ ids: this.selectRowId, result: 2 }).then((res) => {
        if (res.success) {
          this.$message.success('审核成功！')
          this.getList()
        }
      })
    },
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      this.selectRowId = []
      this.selectedRowKeys = []
      rechargeIntegralRecordPage(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
